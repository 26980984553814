<template>
    <div class="bg-gray-100 overflow-hidden h-full">
        <div class="m-8 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <section aria-labelledby="payment_details_heading">
                <div class="shadow rounded-2xl sm:overflow-hidden">
                    <div class="bg-white py-6 px-4 sm:p-6">
                        <div>
                            <h2
                                id="payment_details_heading"
                                class="text-2xl text-left leading-6 font-bold text-gray-900"
                            >
                                {{ $t("support.help") }}
                            </h2>
                            <div class="text-left ml-4 my-5">
                                <p class="font-bold text-sm text-black">
                                    {{ $t("support.general_support") }}
                                </p>
                                <p class="text-sm my-2 text-gray-500">
                                    theDesk Support:
                                </p>
                                <p class="my-2 text-black">
                                    Email: info@thedesk.com.hk
                                </p>
                            </div>
                            <div class="text-left ml-4 my-5">
                                <p class="font-bold text-sm text-black">
                                    {{ $t("support.technical_support") }}
                                </p>
                                <p class="text-sm my-2 text-gray-500">
                                    Flow Client Success Team:
                                </p>
                                <p class="my-2 text-black">
                                    Email: support@flowtheroom.com
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "Support"
}
</script>

<style scoped></style>
